<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="角色" required>
                        <Select v-model="form.role_id" class="w50">
                            <Option :value="0">超级管理员</Option>
                            <Option
                                :value="item.id"
                                v-for="item in roles"
                                :key="item.id"
                            >{{ item.title }}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label="用户名" prop="username" class="w50">
                        <Input :maxlength="45" show-word-limit v-model="form.username" />
                    </FormItem>
                        
                    <template v-if="!show_password">
                        <FormItem>
                            <Button @click="resetPassword">重置密码</Button>
                        </FormItem>
                    </template>
                    <template v-if="show_password">
                        <FormItem label="密码" prop="password">
                            <Input type="password" v-model="form.password" @on-enter="submit"/>
                        </FormItem>
                        <FormItem label="确认密码" prop="passworded">
                            <Input type="password" v-model="form.passworded" @on-enter="submit"/>
                        </FormItem>
                    </template>

                    <FormItem label="状态">
                        <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
                            <span slot="open">正常</span>
                            <span slot="close">锁定</span>
                        </i-switch>
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {
        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    username: '',
                    password: '',
                    role_id : 0,
                    status  : 1
                },
                validate: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                    passworded: [
                        { required: true, message: '请再次输入密码', trigger: 'blur' }
                    ]
                },
                roles: [],
                show_password: true
            }
        },
        created()
        {
            this.loadRoles()
        },
        methods: {
            submit()
            {
                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        if (this.show_password && this.form.password != this.form.passworded)
                            return this.$Message.warning('两次密码不一致')

                        const url = '/admin/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/admin/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                        this.show_password = false
                    })
            },
            loadRoles()
            {
                this.$http.get('/authRole/list', {params: { status: 1 }})
                    .then(res => {
                        this.roles = res
                        if (res.length)
                            this.form.role_id = res[0].id
                            
                        this.id && this.loadData()
                    })
            },
            resetPassword()
            {
                this.form.password   = ''
                this.form.passworded = ''
                this.show_password   = true
            }
        }
    }
</script>